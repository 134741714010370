import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header2 from "./header2";
import Footer2 from "./footer2";
import ToTop from "./totop";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
  });
}

// markup
const Layout2 = ({ children, title, policyStyle, showHeader, isTop }) => {
  const [loadJs, setLoadJs] = useState(false);

  useEffect(() => {
    if (process.env.GATSBY_ENV === "development") {
      if (!loadJs) {
        // const head = document.getElementsByTagName("head")[0];
        // const scrollScript = document.createElement("script");
        // scrollScript.type = "text/javascript";
        // scrollScript.src = "/js/link_scroll.js";
        // head.appendChild(scrollScript);

        // if (showHeader === true) {
        //   const navScript = document.createElement("script");
        //   navScript.type = "text/javascript";
        //   navScript.src = "/js/nav.js";
        //   head.appendChild(navScript);
        // }

        // if (isTop === true) {
        //   const formScript = document.createElement("script");
        //   formScript.type = "text/javascript";
        //   formScript.src = "/js/form.js";
        //   head.appendChild(formScript);
        // }

        setLoadJs(true);
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <div id={showHeader === false ? `container-if` : `container`}>
          {showHeader === true && <Header2 />}
          {children}
          {showHeader === true && <Footer2 />}
        </div>
        {showHeader === true && <ToTop />}
      </div>
    </>
  );
};

export default Layout2;
