import * as React from "react";
import Layout2 from "../../../components/layout2";

// markup
const StaffPage = () => {
  return (
    <Layout2
      title="スタッフ | CO Record &amp; journal"
      policyStyle={false}
      showHeader={true}
      isTop={false}
    >
      <main className="pageStyles">
        <div>
          <div className="staff-image-parent">
            <img
              className="staff-image"
              src="../../images/logo_red.png"
              alt="co_logo"
            />
          </div>

          <p className="staff-head">Staffs</p>

          <p className="staff-post">Exective Producer</p>
          <p className="staff-name">MASUMURA SEIJI</p>
          <p className="staff-post">Producer</p>
          <p className="staff-name">NIKAIDO HIKARU</p>
          <p className="staff-post">Production Manager</p>
          <p className="staff-name">OGINO HIROMI</p>
          <p className="staff-post">Director</p>
          <p className="staff-name">BITO HIROKI</p>
          <p className="staff-post">Project Manager / Server Engineer</p>
          <p className="staff-name">SHIHONMATSU KAZUHISA</p>
          <p className="staff-post">Lead Planner</p>
          <p className="staff-name">SAWADA NORIHIRO</p>
          <p className="staff-post">Infrastructure Engineer</p>
          <p className="staff-name">NGUYEN VAN HOA</p>
          <p className="staff-post">Planner</p>
          <p className="staff-name">MISHIO MIYUU</p>
          <p className="staff-post">UI Designer</p>
          <p className="staff-name">YOMODA NANAHO</p>
          <p className="staff-post">UI Designer</p>
          <p className="staff-name">ODAMURA HIROKI</p>
          <br />
          <p className="staff-text">Crico,Inc.</p>
          <p className="staff-post">Project Manager / Art Director</p>
          <p className="staff-name">WAKUI GENKI</p>
          <p className="staff-post">Illustrator</p>
          <p className="staff-name">WANG MEIXIN</p>
          <br />
          <p className="staff-text">STUDIO・LEMON</p>
          <p className="staff-post">Animation Director</p>
          <p className="staff-name">KIMURA TAKU</p>
          <br />
        </div>
      </main>
    </Layout2>
  );
};

export default StaffPage;
