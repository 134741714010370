import React from "react";

const Footer2 = () => {
  return (
    <footer id="id-footer">
      <div className="flex-footer">
        <a href="https://www.teijin.co.jp/" target="_blank">
          <img
            className="seisiki-logotype"
            src="/img/atari_company_name.jpg"
            alt="帝人株式会社"
          />
        </a>
        <div className="link-box noto-body">
          <a href="/about_v2/terms-conditions/">ご利用条件</a>|
          <a href="/about_v2/policy/">プライバシーポリシー</a>
        </div>
      </div>
      <div className="copyright-box">
        {/* <!-- copyright --> */}
        <small>
          <span className="sp-none">Copyright</span> © TEIJIN LIMITED. All
          rights reserved.
        </small>
      </div>
    </footer>
  );
};

export default Footer2;
