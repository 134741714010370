import React from "react";
import { Link } from "gatsby";

const Header2 = () => {
  return (
    <header id="id-header">
      <div className="header-teijin">
        <a href="https://www.teijin.co.jp/" target="_blank">
          <img className="sp-none" src="/img/BL_Web_PC.png" alt="TEIJIN" />
          <img className="pc-none" src="/img/BL_Web_SP.png" alt="TEIJIN" />
        </a>
      </div>
      <div className="app-header noto-body">
        <a href="/">
          <h1>
            <img src="/img/fv_logo01.png" alt="CO Record &amp; journal" />
          </h1>
        </a>
        <div className="header-button">
          {/* <!-- Gnav--> */}
          <div id="nav-menu-button">
            <hr />
            <hr />
          </div>
          <div id="close-back"></div>
          <nav id="gnav">
            {/* <!-- nav-display-button --> */}
            {/* <!-- END nav-display-button --> */}
            <ul>
              <li>
                <a href="/about_v2/#container">ホーム</a>
              </li>
              <li>
                <a href="/about_v2/#service">特徴</a>
              </li>
              <li>
                <a href="/about_v2/#mail-form">お問い合わせ</a>
              </li>
            </ul>
          </nav>
          {/* <!-- END Gnav--> */}
        </div>
      </div>
    </header>
  );
};

export default Header2;
